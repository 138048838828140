<template>
  <div>
    <c-search-box ref="searchbox">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-select
            :comboItems="upProcessItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="constructTypeCd"
            label="공사형태"
            v-model="searchParam.constructTypeCd"
            @input="changeConstructType"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-card title="공사형태별 공종" class="cardClassDetailForm" style="height:600px;overflow:auto;">
      <template slot="card-button">
        <q-btn-group outline>
          <c-btn label="공사현장에 추가" icon="add" @btnClicked="select" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <q-tree
          ref="processTable"
          :nodes="processTree"
          tick-strategy="strict"
          node-key="processCd"
          label-key="processName"
          children-key="children"
          no-nodes-label="공종 정보가 없습니다."
          :default-expand-all="true"
          :ticked.sync="checkProcess"
        >
        </q-tree>
      </template>
    </c-card>
  </div>
</template>

<script>
import arrayToTree from 'array-to-tree';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'project-company-process-pop',
  props: {
    popupParam: {
      type: Object,
      default: function () {
        return {
          plantCd: '',
          upProcessCd: '',
        };
      },
    },
  },
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
        processCd: '',
        constructTypeCd: null,
      },
      checkProcess: [],
      processList: [],
      processTrees: [],
      upProcessItems: [],
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    processTree() {
      let val = this.convertTree(this.processTrees);
      return val ? val : [];
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.getProcessList();
    },
    changeConstructType(_type) {
      this.$http.url = selectConfig.mdm.process.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        constructTypeCd: _type,
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.processTrees = _result.data;
      },
      () => {
      });
    },
    getProcessList() {
      this.$http.url = selectConfig.mdm.construct.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.upProcessItems = _result.data;
      },);
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upProcessCd',
        customID: 'processCd',
      });
      return tree;
    },
    select() {
      let selectData = [];
      if (!this.checkProcess || this.checkProcess.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공종을 선택하세요.', // 공종을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(this.checkProcess, item => {
          selectData.push({
            plantCd: this.popupParam.plantCd,
            regUserId: this.$store.getters.user.userId,
            code: item,
            processCd: item,
          });
        });
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '기존 공종/작업/작업단계 데이터가 초기화 되며\n\r(기존공정데이터는 사라집니다.)\n\r선택한 공종의 작업/작업단계가\n\r해당 공사현장 데이터로 저장됩니다\n\r그대로 진행하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.pjm.mst.save.url;
            this.$http.type = 'POST';
            this.$http.param = selectData;
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '공사현장 공종데이터가 저장되었습니다.', /* 공사현장 공종데이터가 변경되었습니다.. */
                type: 'success', // success / info / warning / error
              });
              this.$emit('closePopup');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  },
};
</script>
